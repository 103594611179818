// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-gallery-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-pages-rates-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/rates.tsx" /* webpackChunkName: "component---src-pages-rates-tsx" */),
  "component---src-pages-services-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-space-tsx": () => import("/usr/local/nginx/html/other_domains/venue-of-hollywood/src/pages/space.tsx" /* webpackChunkName: "component---src-pages-space-tsx" */)
}


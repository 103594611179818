module.exports = [{
      plugin: require('/usr/local/nginx/html/other_domains/venue-of-hollywood/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/usr/local/nginx/html/other_domains/venue-of-hollywood/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-MXSQFRN","includeInDevelopment":true,"gtmAuth":"","gtmPreview":""},
    },{
      plugin: require('/usr/local/nginx/html/other_domains/venue-of-hollywood/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
